export default {
  ticketlessSeletion: {
    serviceDay: '搭乘日',
    serviceTime: '搭乘时间',
    stationSelect: '选择上下车车站',
    startStationSelect: '请选择上车车站',
    arriveStationSelect: '请选择下车车站',
    returnToTicketList: '返回票券列表',
    toSeatSelect: '前往座位选择',
    currentTicketInfo: '目前的车票信息',
    serviceTrain: '列车信息',
    boughtNum: '购买数量',
    adult: '成人{{num}} 人',
    child: '儿童{{num}} 人',
    seatNo: '{{carNo}}号车{{seatNo}}号',
    allSeatNeedBeSelected: '※须重新选择所有座位。',
    mustSelectArriveStation: '请选择下车车站',
    mustSelectStartStation: '请选择上车车站',
    mustPickServiceDate: '请选择搭乘日期',
    mustPickServiceTime: '请选择搭乘时间',
    serviceDate: '{{year}}年{{month}}月{{date}}日',
    seat: '座位',
    paramsError: '参数无效',
    confirmSelectSeat: `【请注意】
您将被导向新的网际网路网站。
请勿点击浏览器的返回按钮或使用历史记录跳转功能，
并请避免同时在多个浏览器标签或视窗中进行操作。`
  },
  ticketlessSeletionCallback: {
    paramExpVoucherError: 'ExpVoucher参数错误',
    pleaseWait: '处理中，请稍候。'
  },
  ticketlessSeletionAbort: {
    paramExpVoucherError: 'ExpVoucher参数错误',
    tips: '座位选择处理过程中断'
  }
};
