export default {
  ticketlessSeletion: {
    serviceDay: '搭乘日',
    serviceTime: '搭乘時間',
    stationSelect: '選擇上下車車站',
    startStationSelect: '請選擇上車車站',
    arriveStationSelect: '請選擇下車車站',
    returnToTicketList: '回到票券列表',
    toSeatSelect: '前往座位選擇',
    currentTicketInfo: '目前的車票資訊',
    serviceTrain: '列車資訊',
    boughtNum: '購買數量',
    adult: '成人{{num}} 人',
    child: '兒童{{num}} 人',
    seatNo: '{{carNo}}號車{{seatNo}}號',
    allSeatNeedBeSelected: '※須重新選擇所有座位。',
    mustSelectArriveStation: '請選擇下車車站',
    mustSelectStartStation: '請選擇上車車站',
    mustPickServiceDate: '請選擇搭乘日期',
    mustPickServiceTime: '請選擇搭乘時間',
    serviceDate: '{{year}}年{{month}}月{{date}}日',
    seat: '座位',
    paramsError: '參數無效',
    confirmSelectSeat: `【請注意】
您將被導向新的網際網路網站。
請勿點擊瀏覽器的返回按鈕或使用歷史記錄跳轉功能，
並請避免同時在多個瀏覽器標籤或視窗中進行操作。`
  },
  ticketlessSeletionCallback: {
    paramExpVoucherError: 'ExpVoucher參數錯誤',
    pleaseWait: '處理中，請稍候。'
  },
  ticketlessSeletionAbort: {
    paramExpVoucherError: 'ExpVoucher參數錯誤',
    tips: '座位選擇處理過程中斷'
  }
};
