export default {
  ticketlessSeletion: {
    serviceDay: '탑승일',
    serviceTime: '승차 시간',
    stationSelect: '승하차 역 선택',
    startStationSelect: '승차역을 선택해 주세요',
    arriveStationSelect: '하차역을 선택해 주세요',
    returnToTicketList: '티켓 목록으로 돌아가기',
    toSeatSelect: '좌석 선택으로',
    currentTicketInfo: '현재 승차권 정보',
    serviceTrain: '승차 열차',
    boughtNum: '구매 인원수',
    adult: '어른 {{num}} 명',
    child: '어린이 {{num}}명',
    seatNo: '{{carNo}}호 차량 {{seatNo}}번 좌석',
    allSeatNeedBeSelected: '※모든 좌석을 다시 지정해주시기 바랍니다',
    mustSelectArriveStation: '하차역을 선택해주세요',
    mustSelectStartStation: '승차역을 선택해 주세요',
    mustPickServiceDate: '승차일을 선택해 주세요',
    mustPickServiceTime: '승차 시간을 선택해 주세요',
    serviceDate: '{{year}}년{{month}}월{{date}}일',
    seat: '좌석',
    paramsError: '파라메터가 비정상입니다',
    confirmSelectSeat: `[주의]
이 페이지에서 외부 사이트로 이동합니다. 
브라우저의 뒤로 가기 버튼을 누르거나, 히스토리 점프 기능(이력 점프 기능)을 사용하지 마십시오. 
또한, 여러 브라우저 탭이나 창에서 동시에 작업하는 것도 삼가 주시기 바랍니다.`
  },
  ticketlessSeletionCallback: {
    paramExpVoucherError: 'ExpVoucher 파라메터가 비정상입니다',
    pleaseWait: '처리 중이니 잠시 기다려주세요.'
  },
  ticketlessSeletionAbort: {
    paramExpVoucherError: 'ExpVoucher 파라메터가 비정상입니다',
    tips: '좌석 선택이 중단되었습니다.'
  }
};
