/* eslint-disable react-refresh/only-export-components */
import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  useRouteError
} from 'react-router-dom';
import { BaseLayout } from '@/layouts';

const NankaiTicketless = lazy(() => import('@/views/NankaiTicketless'));
const NankaiTicketlessCallback = lazy(
  () => import('@/views/NankaiTicketlessCallback')
);
const NankaiTicketlessAbort = lazy(
  () => import('@/views/NankaiTicketlessAbort')
);
const NotFound = lazy(() => import('@/views/NotFound'));

function RootErrorBoundary() {
  const error = useRouteError() as Error;
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    location.reload();
  }
  return null;
}

const lazyView = (view: JSX.Element) => {
  return <Suspense fallback="Loading...">{view}</Suspense>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route path="/" element={<BaseLayout />}>
        <Route index element={<NotFound />} />
        <Route
          path="nankai-ticketless/:token/:bookingId/"
          element={<NankaiTicketless />}
        />
        <Route
          path="nankai-ticketless-callback"
          element={<NankaiTicketlessCallback />}
        />
        <Route
          path="nankai-ticketless-abort"
          element={<NankaiTicketlessAbort />}
        />
        <Route path="*" element={lazyView(<NotFound />)} />
      </Route>
    </Route>
  )
);

export default router;
