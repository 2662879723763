export default {
  ticketlessSeletion: {
    serviceDay: '乗車日',
    serviceTime: '乗車時刻',
    stationSelect: '乗降車駅選択',
    startStationSelect: '乗車駅を選択してください',
    arriveStationSelect: '降車駅を選択してください',
    returnToTicketList: 'チケット一覧に戻る',
    toSeatSelect: '座席選択へ',
    currentTicketInfo: '現在の乗車券情報',
    serviceTrain: '列車情報',
    boughtNum: '購入数',
    adult: '大人 {{num}} 人',
    child: '小児 {{num}} 人',
    seatNo: '{{carNo}}号車{{seatNo}}番',
    allSeatNeedBeSelected: '※全ての座席を再度選択してください。',
    mustSelectArriveStation: '降車駅を選択してください',
    mustSelectStartStation: '乗車駅を選択してください',
    mustPickServiceDate: '乗車日を選択してください',
    mustPickServiceTime: '乗車時刻を選択してください',
    serviceDate: '{{year}}年{{month}}月{{date}}日',
    seat: '座席',
    paramsError: 'パラメータが不正です',
    confirmSelectSeat: `【注意】
この先外部サイトへ遷移します。
ブラウザの戻るボタンを押したり、履歴ジャンプ機能を使用しないでください。
また複数のブラウザタブやウィンドウでの同時に操作もお止めください。`
  },
  ticketlessSeletionCallback: {
    paramExpVoucherError: 'ExpVoucherパラメーターが間違っています',
    pleaseWait: '処理中、そのままお待ちください'
  },
  ticketlessSeletionAbort: {
    paramExpVoucherError: 'ExpVoucherパラメーターが間違っています',
    tips: '座席選択処理失敗されました'
  }
};
