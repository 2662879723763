export default {
  ticketlessSeletion: {
    serviceDay: 'Boarding date',
    serviceTime: 'Boarding time',
    stationSelect: 'Please choose your boarding and disembarking station.',
    startStationSelect: 'Please choose your boarding station.',
    arriveStationSelect: 'Please choose your disembarking station.',
    returnToTicketList: 'Return to the ticket list',
    toSeatSelect: 'Go to seat selection',
    currentTicketInfo: 'Current ticket information',
    serviceTrain: 'Train information',
    boughtNum: 'Number of tickets purchased',
    adult: '{{num}} adults',
    child: '{{num}} children',
    seatNo: 'Seat {{seatNo}} in car {{carNo}}',
    allSeatNeedBeSelected: 'Please select all the seats once again.',
    mustSelectArriveStation: 'Please choose your disembarking station.',
    mustSelectStartStation: 'Please choose your boarding station.',
    mustPickServiceDate: 'Please choose the date on which you plan to board.',
    mustPickServiceTime: 'Please select the time for your ride.',
    serviceDate: '{{month}}month{{date}}day, {{year}}year',
    seat: 'Seat',
    paramsError: 'The parameter entered is not valid.',
    confirmSelectSeat: `[Warning]  
You will be redirected to an external website.
Please avoid using the browser's back button or history jump function. 
Additionally, refrain from operating multiple browser tabs or windows simultaneously.`
  },
  ticketlessSeletionCallback: {
    paramExpVoucherError: 'ExpVoucher parameter is incorrect.',
    pleaseWait:
      'This request is currently being processed. Kindly wait for a while.'
  },
  ticketlessSeletionAbort: {
    paramExpVoucherError: 'ExpVoucher parameter is incorrect.',
    tips: 'The process of selecting a seat has been interrupted.'
  }
};
